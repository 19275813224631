import React from "react"
import { IMenuItem } from "../../interfaces"

export const expandIcon = (
	<svg height="32" viewBox="0 0 32 32" width="32">
		<path
			d="M 27.907752,9.0115462 15.922015,21.000176 3.9362786,9.0101002 c -0.5714383,-0.571439 -1.4958662,-0.571439 -2.0673045,0 -0.5699916,0.571438 -0.5699916,1.4973128 0,2.0687508 L 14.887639,24.101857 v 0 0 c 0.569992,0.571438 1.495867,0.571438 2.065858,0 L 29.972163,11.078851 c 0.569991,-0.571438 0.569991,-1.4987598 0,-2.0701978 -0.568545,-0.568545 -1.49442,-0.568545 -2.064411,0.0029 z"
			fill="#ffffff"
			strokeWidth="1.44667912"
		/>
	</svg>
)

export const itemsFilter = (item: IMenuItem, rawFilter: string, idx: number) => {
	let doesMatch = false
	const filter = rawFilter.toLocaleLowerCase()
	if (item.name.toLocaleLowerCase().includes(filter)) doesMatch = true
	if (item.title.toLocaleLowerCase().includes(filter)) doesMatch = true
	if (item.controllerOptions && item.controllerOptions.tags) {
		item.controllerOptions.tags.forEach((tag: string) => {
			if (tag.toLocaleLowerCase().includes(filter)) doesMatch = true
		})
	}
	return doesMatch
}

export type UpOrDownNavigation = "up" | "down"

export const findNextAnchorItem = (el: HTMLUListElement, dir: UpOrDownNavigation) => {
	const currentEl = document.activeElement as HTMLAnchorElement | HTMLButtonElement
	const allAnchors = el.querySelectorAll("a, button.demo-nav-link")
	const index = Array.from(allAnchors).indexOf(currentEl)

	if (index > -1) {
		let nextToReceiveFocusIndex = dir === "down" ? index + 1 : index - 1
		if (nextToReceiveFocusIndex > -1) {
			if (nextToReceiveFocusIndex <= allAnchors.length - 1) {
				const element = (allAnchors[nextToReceiveFocusIndex] as any)
				if (element.offsetParent === null) {
					while((allAnchors[nextToReceiveFocusIndex] as any) && (allAnchors[nextToReceiveFocusIndex] as any).offsetParent === null) {
						nextToReceiveFocusIndex = dir === "down" ? nextToReceiveFocusIndex += 1 : nextToReceiveFocusIndex -= 1
					}
				}

				(allAnchors[nextToReceiveFocusIndex] as any).focus()
			} else {
				(allAnchors[0] as any).focus()
			}
		} else {
			(allAnchors[allAnchors.length - 1] as any).focus()
		}
	}
}

export const removeHiddenItems = (item: IMenuItem) => {
	if (!!item.hideFromNav) {
		return false
	}
	if (item.children) {
		item.children = item.children.filter(removeHiddenItems)
	}
	return true
}
