import React from "react"
import { registry } from "../../controllers"
import { Loading } from "../Loading"
import { IComponentLoaderProps  } from "./IComponentLoaderProps"

export class ComponentLoader extends React.Component<IComponentLoaderProps> {
	public componentDidMount() {
		this.props.loader(this.props.data, this.props.promises)
	}
	public render() {
		if (this.props.isLoading(this.props.data.name)) {
			return <Loading />
		}
		if (this.props.data.controllerName) {
			const Component = (registry as any)[this.props.data.controllerName]
			return <Component name={this.props.data.name} />
		}
		return <div>No "contollerName" registred for component "{this.props.data.name}"</div>
	}
}

export default ComponentLoader
