import React, { useState } from "react"
import Icon from "../../Icon"
import { ExclamationTriangle } from "../../Icon/icons/ExclamationTriangle"
import { Info } from "../../Icon/icons/Info"
import { TimesCircle } from "../../Icon/icons/TimesCircle"
import "./FieldWrapper.scss"
import { IFieldWrapperProps } from "./FieldWrapper.types"

export const FieldWrapper: React.FunctionComponent<IFieldWrapperProps> = ({
	isRequired,
	label,
	id,
	invalidMessage,
	warningMessage,
	helpMessage,
	isHiddenLabel,
	isValid,
	describedById,
	children,
	tooltip
}) => {

	const [showTooltip, setShowTooltip] = useState(false)

	const toggleTooltip = (event: React.MouseEvent<SVGSVGElement, MouseEvent> | React.KeyboardEvent<SVGSVGElement>) => {
		event.preventDefault()
		setShowTooltip(!showTooltip)
	}

	const onKeyPressed = (event: React.KeyboardEvent<SVGSVGElement>) => {
		if (event.key === "Enter") {
			toggleTooltip(event)
		}
	}

	return (
		<div className="vui-field-wrapper" data-required={isRequired || undefined}>
			<label
				htmlFor={id}
				data-testid="vui-field-wrapper-label"
				className={`vui-field-wrapper-label ${isHiddenLabel ? "vui-field-wrapper--hidden" : ""}`}>
				<span>{label} {
					tooltip && (
						showTooltip
							? <Icon
								icon={TimesCircle}
								aria-expanded="true"
								tabIndex={0}
								aria-label="Hide tooltip"
								role="tooltip"
								onClick={toggleTooltip}
								onKeyPress={onKeyPressed}
							/>
							: <Icon
								icon={Info}
								aria-expanded="false"
								tabIndex={0}
								aria-label="Show tooltip"
								role="tooltip"
								onClick={toggleTooltip}
								onKeyPress={onKeyPressed}
								data-testid="vui-field-wrapper_tooltip-icon"
							/>
					)
				}
				</span>
			</label>
			{
				showTooltip &&
				<div
					className="vui-field-wrapper_tooltip-message"
					data-testid="vui-field-wrapper_tooltip-message"
					id={describedById}
				>
					{tooltip}
				</div>
			}
			<div className="vui-field-wrapper-content">
				{children}
			</div>
			{((invalidMessage && !isValid) || warningMessage || helpMessage) && (
				<div className="vui-field-wrapper_messages" id={describedById}>
					{invalidMessage && !isValid &&
						<div
							className="vui-field-wrapper_messages-invalid"
							data-testid="vui-field-wrapper_messages-invalid"
						>
							<Icon icon={ExclamationTriangle} />
							{invalidMessage}
						</div>}
					{warningMessage &&
						<div
							className="vui-field-wrapper_messages-warning"
							data-testid="vui-field-wrapper_messages-warning"
						>
							<Icon icon={Info} />
							{warningMessage}
						</div>}
					{helpMessage &&
						<div
							className="vui-field-wrapper_messages-help"
							data-testid="vui-field-wrapper_messages-help"
						>
							<p>{helpMessage}</p>
						</div>}
				</div>
			)}
		</div>
	)
}

export default FieldWrapper
