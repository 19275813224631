import React from "react"

import { IProperty } from "../typeParser/interfaces"
import { parse } from "../typeParser/Parse"
import { TypeTable } from "./TypeTable"

export interface ITypeInfoProps {
	headingId: string
	typeDoc: string
	heading?: string
}
export const TypeInfo: React.StatelessComponent<ITypeInfoProps> = (props) => {
	const parsedTypeDoc: IProperty[] = parse(props.typeDoc)
	parsedTypeDoc.sort((a, b) => a.name.localeCompare(b.name))

	return (
		<React.Fragment>
			<h2 id={props.headingId}>{props.heading}</h2>
			{parsedTypeDoc.map((type, idx) => (
				<TypeTable key={idx} members={type.property} type={type.propertyType} typeName={type.propertyName} extenders={type.extenders}/>
			))}
		</React.Fragment>
	)
}
TypeInfo.defaultProps = {
	heading: "Types"
}
export default TypeInfo
