@import "../../colors/Colors.scss";

.vui-field {
    display: flex;

    .vui-field-wrapper-content {
        background: #ffffff;
        position: relative;
        
        .vui-field-wrapper-icon {
            position: absolute;
            right: 10px;
            top: 8px;
            z-index: 1;
        }
    }

    .vui-field-control {
        appearance: none;
        background: transparent;
        box-sizing: border-box;
        border-radius: 5px;
        box-sizing: border-box;
        height: 40px;
        padding-left: 16px;
        border: none;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: $mineGrey80;
        box-shadow: 0 0 0 1px $indigo30;
        width: 100%;
        z-index: 2;
        position: relative;

        // Unstyle the caret on `<select>`s in IE10+.
        &::-ms-expand {
            display: none;
        }
        
        // Remove clear button in IE10+.
        &::-ms-clear {
            display: none;
        }

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
        }

        &:focus {
            box-shadow: 0 0 0 2px $indigo;
            outline: none;
        }

        &[aria-invalid], &[data-vui-field-isvalidated="true"], &[data-vui-field-isvalidating="true"] {

            padding-right: (10px + 24px + 10px);
        }

        &[aria-invalid] {
            box-shadow: 0 0 0 1px $rubin;
            &:focus {
                border: none;
                box-shadow: 0 0 0 2px $rubin;
            }
        }

        &[disabled] {
            background: $mineGrey10;
            box-shadow:0 0 0 1px $mineGrey20;
        }

        &[data-vui-field-isvalidated="true"] {
            box-shadow: 0 0 0 1px $amulet;
            &:focus {
                box-shadow: 0 0 0 2px $amulet;
            }
        }

        &[data-vui-field-isvalidating="true"] {
            box-shadow: 0 0 0 1px $indigo30;
            &:focus {
                box-shadow: 0 0 0 2px $indigo;
            }
        }
    }
}

.vui-field-wrapper {
    display: flex;
    flex-direction: column;
    font-family: Avenir Next;
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: $prussian;
    width: 100%;

    &-label {
        font-weight: 600;
        padding-bottom: 8px;
    }

    &_tooltip-icon {
        padding-left: 10px;
        cursor: pointer;
    }

    &_tooltip-message {
        background-color: $prussian;
        padding: 16px;
        margin-bottom: 10px;
        border-radius: 5px;
        color: #fff;
    }

    &--hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    &-spinner {
        animation: spin 2s linear infinite;
    }

    &_messages {
        padding-top: 10px;
        color: $slateGrey;
        p {
            margin: 0;
        }
        &-invalid {
            color: $rubin110;
        }

        &-warning {
            color: $indigo;
        }
    }
}

@keyframes spin {
    100% {
        transform: rotate(359deg);
    }
}