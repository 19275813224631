import React from "react"
import { Provider } from "react-redux"
import VeracityFonts from "../../src/VeracityFonts"
import nav from "../data/nav"
import "./App.scss"
import { Routing } from "./components/Routing"
import { store } from "./store"

export default class App extends React.Component {
	public render() {
		return (
			<Provider store={store}>
				<VeracityFonts broaderView />
				<Routing items={nav} />
			</Provider>
		)
	}
}
