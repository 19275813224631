import React from "react"
import { MarkdownViewer } from "../../components/MarkdownViewer"

import Header from "../../components/ComponentHeader"
import Menu from "../../components/ComponentMenu"

import { DemoViewer } from "../../components/DemoViewer"
import { Tags } from "../../components/Tags"
import { TypeViewer } from "../../components/TypeViewer"
import { IControllerProps } from "../shared/IControllerProps"

export class ComponentController extends React.PureComponent<IControllerProps> {
	public render() {
		const { name, getProperty, isBetaRoute } = this.props

		const path = getProperty(name, "path")
		const hasExamples = getProperty(name, "demos")
		const tags = getProperty(name, "tags")
		const readme = getProperty(name, isBetaRoute ? "betaReadme" : "readme")
		const demos = getProperty(name, isBetaRoute ? "betaDemos" : "demos")
		const type = getProperty(name, isBetaRoute ? "betaTypeDoc" : "typeDoc")

		return (
			<div className="details-container">
				<Header heading={name}/>
				<Menu
					hasExamples={!!hasExamples}
					hasTypes={!!type}
					path={path}
					name={name}
					isBetaRoute={isBetaRoute}
				/>
				<Tags tags={tags} />
				<MarkdownViewer md={readme || ""} />
				{demos && <DemoViewer demos={demos} />}
				{type && <TypeViewer types={type} />}
			</div>
		)
	}
}
export default ComponentController
