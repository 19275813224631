import { connect } from "react-redux"
import { withRouter } from "react-router"
import * as uiDucks from "../../ducks/ui.duck"
import { IBaseState } from "../../interfaces"
import { Navigation } from "./Navigation"
import { removeHiddenItems } from "./navigationUtils"

export default withRouter(
	connect((globalState: IBaseState) => ({
		expanded: uiDucks.getMenuOpen(globalState),
		items: uiDucks.getMenuItems(globalState).filter(removeHiddenItems)
	}),
	(dispatch: any) => ({
		setExpanded: (val: boolean) => dispatch(uiDucks.setMenuOpen(val))
	}))(Navigation)
)
