import React from "react"
import { withRouter } from "react-router-dom"
import showdown from "showdown"
import BodyText from "../../../../src/BodyText"
import { highlightHtml } from "../../utils/codeHighlighter"
import { IMarkdownViewerProps } from "./IMarkdownViewerProps"
import "./MarkdownViewer.scss"

showdown.setFlavor("github")

class MarkdownViewerBase extends React.PureComponent<IMarkdownViewerProps> {
	public makeHtmlFromMarkdown() {
		const converter = new showdown.Converter()
		return converter.makeHtml(this.props.md)
	}
	public getCode() {
		const html = this.makeHtmlFromMarkdown()
		const div = document.createElement("div")
		div.innerHTML = html
		const highlighted = highlightHtml(div)
		return {__html: highlighted.innerHTML}
	}
	public handleClick = (e: React.MouseEvent) => {
		e.persist()
		const el = e.target as HTMLAnchorElement
		const elAttr = el.getAttribute("href")
		if (el && elAttr && elAttr.startsWith("/")) {
			e.preventDefault()
			this.props.history.push(elAttr)
		}
	}
	public render() {
		return (
			<div className="demo-container">
				<BodyText className="demo-markdown-viewer" classToProp>
					<div
						id="comp-doc"
						dangerouslySetInnerHTML={this.getCode()}
						onClick={this.handleClick}
					>
					</div>
				</BodyText>
			</div>
		)
	}
}

export const MarkdownViewer = withRouter(MarkdownViewerBase)
export default MarkdownViewer
