import React from "react"
import { Navigation } from "../Navigation"
import { Content } from "./Content"
import "./MainLayout.scss"

export class MainLayout extends React.Component {
	public render() {
		return (
			<div className="demo-wrapper">
				<Navigation />
				<Content>
					{this.props.children}
				</Content>
			</div>
		)
	}
}
