import React from "react"
import "./EasterEgg.scss"

export class EasterEgg extends React.PureComponent {
	public state = {
		enabled: false
	}
	public easterEggContainer: React.RefObject<HTMLDivElement> = React.createRef()
	private easterEgg: React.RefObject<HTMLCanvasElement> = React.createRef()
	private interval: any
	private storage: any = {}
	public componentDidMount() {
		document.addEventListener("keyup", this.handleKeys)
	}
	public componentWillUnmount() {
		document.removeEventListener("keyup", this.handleKeys)
	}
	public resizeElement = (element?: HTMLCanvasElement, parent?: HTMLDivElement) => () => {
		if (parent && element) {
			element.width = parent.offsetWidth
			element.style.width = parent.offsetWidth + "px"
			element.height = window.innerHeight
			element.style.height = window.innerHeight + "px"
		}
	}
	public registerEasterEgg = () => {
		// thanks to https://codepen.io/P3R0/pen/MwgoKv
		if (this.easterEgg.current) {
			const c = this.easterEgg.current
			const ctx = c.getContext("2d")

			const parent: any = c.parentNode
			this.resizeElement(c, parent)()

			window.addEventListener("resize", this.resizeElement(c, parent))

			const characters = "leet1337".split("")

			const fontSize = 10
			const columns = c.width/fontSize // number of columns for the rain
			// an array of drops - one per column
			const drops: any[] = []
			// x below is the x coordinate
			// 1 = y co-ordinate of the drop(same for every drop initially)
			for(let x = 0; x < columns; x++) {
				drops[x] = 1
			}

			// drawing the characters
			function draw() {
				if (ctx) {
					// Black BG for the canvas
					// translucent BG to show trail
					ctx.font = "Avenir Next"
					ctx.fillStyle = "rgba(0, 0, 0, 0.1)"
					ctx.fillRect(0, 0, c.width, c.height)

					ctx.fillStyle = "#0F0" // green text
					ctx.font = fontSize + "px arial"
					// looping over drops
					for(let i = 0; i < drops.length; i++) {
						// a random character to print
						const text = characters[Math.floor(Math.random()*characters.length)]
						// x = i*font_size, y = value of drops[i]*font_size
						ctx.fillText(text, i*fontSize, drops[i]*fontSize)

						// sending the drop back to the top randomly after it has crossed the screen
						// adding a randomness to the reset to make the drops scattered on the Y axis
						if(drops[i]*fontSize > c.height && Math.random() > 0.975) {
							drops[i] = 0
						}

						// incrementing Y coordinate
						drops[i]++
					}
				}
			}

			this.interval = setInterval(draw, 33)
		}
	}
	public unregisterEasterEgg = () => {
		if (this.interval) clearInterval(this.interval)
		window.removeEventListener("resize", this.resizeElement())
	}
	public close = () => {
		this.unregisterEasterEgg()
		this.setState({
			enabled: false
		})
	}
	public open = () => {
		this.setState({
			enabled: true
		}, () => {
			this.registerEasterEgg()
			if (this.easterEggContainer.current) {
				this.easterEggContainer.current.focus()
			}
		})
	}
	public handleKeys = (e: KeyboardEvent) => {
		if (!this.state.enabled) {
			if (e.keyCode === 17) {
				this.storage.hasOwnProperty(e.keyCode) ? this.storage[e.keyCode] += 1 : this.storage[e.keyCode] = 1
				if (this.storage[17] && this.storage[17] > 4) {
					this.open()
					this.storage = {}
				}
			} else {
				this.storage = {}
			}
		}
	}
	public watchEsc = (e: React.KeyboardEvent) => {
		e.persist()
		if(e.keyCode === 27) {
			this.close()
		}
	}
	public render() {
		return (
			<div className="demo-easter-egg-fs" ref={this.easterEggContainer} tabIndex={-1} onKeyDown={this.watchEsc}>
				{this.state.enabled &&
					<>
						<canvas className="demo-easter-egg" ref={this.easterEgg}></canvas>
					</>
				}
			</div>
		)
	}
}
