import { connect } from "react-redux"
import * as uiDucks from "../../ducks/ui.duck"
import { IBaseState } from "../../interfaces"
import { DemoViewer } from "./DemoViewer"

export default connect((globalState: IBaseState) => ({
	fullscreen: uiDucks.getFullscreenExample(globalState)
}), (dispatch: any) => ({
	setFullscreen: (val: boolean) => dispatch(uiDucks.setFullscreenExample(val))
}))(DemoViewer)
