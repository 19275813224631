import FontFaceObserver from "fontfaceobserver"
import React from "react"
import { IFontConfig, IFontLoaderProps } from "./FontLoader.types"

export const loadFont = async (fontFamilyName: string, testString?: any, config?: FontFaceObserver.FontVariant): Promise<any> => {
	const font = new FontFaceObserver(fontFamilyName, config)
	const fontResult = await font.load(testString)
	return {
		res: fontResult,
		name,
		config
	}
}
export const loadFontConfig = async (fontConfig: IFontConfig) => {
	const allConfigs = fontConfig.configs || [{}]
	return Promise.all(allConfigs.map((config) => {
		const {testString, ...rest} = config
		return loadFont(fontConfig.name, testString, rest)
	}))
}

export class FontLoader extends React.PureComponent<IFontLoaderProps> {
	private static defaultProps = {
		config: []
	}

	public componentWillReceiveProps(nextProps: IFontLoaderProps) {
		if (!nextProps.config) { return }
		if (nextProps.config !== this.props.config) {
			this.loadFontsAndApplyClasses(nextProps.config)
		}
	}
	public componentDidMount() {
		if (!this.props.config) { return }
		this.loadFontsAndApplyClasses(this.props.config)
	}

	public render() {
		return this.props.children || null
	}

	protected loadFontsAndApplyClasses = async (config: IFontConfig[]) => {
		await Promise.all(config.map(async (aConfig) => {
			await loadFontConfig(aConfig)
			document.body.classList.add(aConfig.className)
		}))
	}
}
export default FontLoader
