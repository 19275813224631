@mixin elements-code{
  code{
    font-family: Consolas, Courier New, Courier, monospace;
    background-color:$bodytext-inline-code-background;
    color: inherit;
    padding:3px 0;
    font-size: 14px;
    border-radius:3px;

    &::before, &::after{
      content: "\00a0";
      letter-spacing: -0.2em;
    }
  }

  pre{
    display:block;
    box-sizing:border-box;
    background-color:$bodytext-block-code-background;
    padding:3px 6px;
    margin:0 0 12px 0;
    color: inherit;
	line-height: 1.25;
	overflow-x: auto;
    > code{
      background-color:transparent;
      padding:0;
      &::before, &::after{
        content: "";
      }
    }
    &:last-child{
      margin-bottom:0;
    }
  }
}