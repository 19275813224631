/*tslint:disable:object-literal-key-quotes*/

/**********************************************
This file is dynamically generated by compile-nav.ts
Do not modify it directly!
*/

import { IMenuItem } from "../src/interfaces"

const nav: IMenuItem[] = [
	{
		"title": "🚀 Getting started",
		"name": "home",
		"path": "/",
		"controllerName": "md",
		"controllerOptions": () => import("../../src/README.md")
	},
	{
		"title": "👷 Contribute",
		"name": "contrib",
		"path": "/contrib",
		"controllerName": "md",
		"controllerOptions": () => import("../../src/CONTRIBUTE.md")
	},
	{
		"title": "🚉 CLI",
		"name": "cli",
		"path": "/cli",
		"controllerName": "md",
		"controllerOptions": () => import("../../src/CLI.md")
	},
	{
		"title": "Proposal Template",
		"name": "proposal",
		"path": "/proposal-template",
		"controllerName": "md",
		"controllerOptions": () => import("../../src/PROPOSAL.md"),
		"hideFromNav": true
	},
	{
		"title": "📦 Components",
		"name": "components",
		"controllerName": "md",
		"controllerOptions": () => import("../../src/COMPONENTS.md"),
		"children": [
			{
				"title": "Body text",
				"name": "BodyText",
				"path": "/BodyText",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Body text",
					"name": "BodyText",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/BodyText/BodyText.md"),
						"demos": () => import("../../src/BodyText/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Button",
				"name": "Button",
				"path": "/Button",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Button",
					"name": "Button",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/Button/Button.md"),
						"demos": () => import("../../src/Button/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Colors",
				"name": "Colors",
				"path": "/Colors",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Colors",
					"name": "Colors",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/colors/Colors.md"),
						"demos": () => import("../../src/colors/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Font loader",
				"name": "FontLoader",
				"path": "/FontLoader",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Font loader",
					"name": "FontLoader",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/FontLoader/FontLoader.md"),
						"demos": () => import("../../src/FontLoader/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Checkbox",
				"name": "Checkbox",
				"path": "/Checkbox",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Checkbox",
					"name": "Checkbox",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/form/Checkbox/Checkbox.md"),
						"demos": () => import("../../src/form/Checkbox/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Custom select",
				"name": "CustomSelect",
				"path": "/CustomSelect",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Custom select",
					"name": "CustomSelect",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/form/CustomSelect/CustomSelect.md"),
						"demos": () => import("../../src/form/CustomSelect/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Select",
				"name": "Select",
				"path": "/Select",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Select",
					"name": "Select",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/form/Select/Select.md"),
						"demos": () => import("../../src/form/Select/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Text field",
				"name": "TextField",
				"path": "/TextField",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Text field",
					"name": "TextField",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/form/TextField/TextField.md"),
						"demos": () => import("../../src/form/TextField/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Toggle",
				"name": "Toggle",
				"path": "/Toggle",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Toggle",
					"name": "Toggle",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/form/Toggle/Toggle.md"),
						"demos": () => import("../../src/form/Toggle/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Icon",
				"name": "Icon",
				"path": "/Icon",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Icon",
					"name": "Icon",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/Icon/Icon.md"),
						"demos": () => import("../../src/Icon/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "SVG",
				"name": "SVG",
				"path": "/SVG",
				"controllerName": "component",
				"controllerOptions": {
					"title": "SVG",
					"name": "SVG",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/Svg/SVG.md"),
						"demos": () => import("../../src/Svg/demos")
					},
					"tags": [
						"react"
					]
				}
			},
			{
				"title": "Veracity fonts",
				"name": "VeracityFonts",
				"path": "/VeracityFonts",
				"controllerName": "component",
				"controllerOptions": {
					"title": "Veracity fonts",
					"name": "VeracityFonts",
					"menuPath": "components",
					"paths": {
						"readme": () => import("../../src/VeracityFonts/VeracityFonts.md"),
						"demos": () => import("../../src/VeracityFonts/demos")
					},
					"tags": [
						"react"
					]
				}
			}
		]
	}
]

export default nav
