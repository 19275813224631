import React from "react"

import { IEnumProperty, IInterfaceProperty, Property, PropertyType } from "../typeParser/interfaces"
import EnumTable from "./EnumTable"
import InterfaceTable from "./InterfaceTable"

import "./TypeTable.scss"

export interface ITypeTableProps {
	typeName: string
	type: PropertyType
	members: Property[]
	extenders?: string[]
}

export class TypeTable extends React.PureComponent<ITypeTableProps> {
	public renderInterfaceTable(members: IInterfaceProperty[]): JSX.Element {
		return (<InterfaceTable members={members}/>)
	}
	public renderEnumTable(members: IEnumProperty[]): JSX.Element {
		return (<EnumTable members={members}/>)
	}

	public render() {
		const {typeName, type, members, extenders} = this.props

		const hasExtenders = extenders && extenders.length > 0

		return (
			<div className="TypeTable-Container">
				<h3>{typeName}{hasExtenders && (<span className="TypeTable-extenders"> extends {extenders!.join(", ")}</span>)}</h3>
				{type === PropertyType.interface ?
					this.renderInterfaceTable(members as IInterfaceProperty[]) :
					this.renderEnumTable(members as IEnumProperty[])}
			</div>
		)
	}
}
export default TypeTable
