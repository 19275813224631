import { IStylableProps } from "../Generic.types"

export enum IconSize {
	/**
	 * 20x20 px
	 */
	Small = 20,
	/**
	 * 24x24 px
	 */
	Medium = 24,
	/**
	 * 30x30 px
	 */
	Large = 30,
	/**
	 * 72x72 px
	 */
	ExtraLarge = 72
}

export interface IIconProps extends IStylableProps, React.SVGProps<SVGSVGElement> {
	icon: () => JSX.Element
	/**
	 * @default IconSize.Medium
	 */
	size?: IconSize
	width?: number
	role?: string
}
