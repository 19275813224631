import cn from "classnames"
import React from "react"
import { NavLink } from "react-router-dom"
import { version } from "../../../data/version"
import { IMenuItem } from "../../interfaces"
import { EasterEgg } from "../EasterEgg"
import { INavigationProps, INavigationState, ISubItems } from "./interfaces"
import "./Navigation.scss"
import { expandIcon, findNextAnchorItem, itemsFilter } from "./navigationUtils"

export class Navigation extends React.Component<INavigationProps, INavigationState> {
	public state: INavigationState = {
		subItems: {},
		filter: "",
		match: {
			single: false,
			singlePath: "",
			items: []
		},
		showKeyboardMessage: false
	}
	private ulRef: React.RefObject<HTMLUListElement> = React.createRef()
	private filterField: React.RefObject<HTMLInputElement> = React.createRef()
	public componentDidMount() {
		this.registerKeyboardShortcuts()
	}
	public componentDidUpdate = (prevProps: INavigationProps, prevState: INavigationState) => {
		if (this.state.filter === "" && prevState.filter !== "") {
			this.setState({ subItems: {} })
		}
		if (this.props.items.length && prevProps.items.length === 0) {
			this.findActiveMenuItem()
		}
	}
	public componentWillUnmount() {
		document.onkeyup = null
	}
	public findActiveMenuItem = () => {
		const activeRoute = window.location.pathname
		const isChildActive = (child?: IMenuItem[]) => {
			if (!child) return []
			return child.filter((i) => i.path && i.path === activeRoute)
		}
		const findActiveRoute = (item: IMenuItem, index: number) => {
			const activeChild = isChildActive(item.children)
			const rootActive = item.path && item.path === activeRoute
			if (activeChild.length > 0 || rootActive) {
				this.toggleSubItem(index)()
			}
		}
		this.props.items.forEach(findActiveRoute)
	}
	public toggleSubItem = (idx: number) => () => {
		const subItems: ISubItems = { ...this.state.subItems }
		subItems[idx] = this.state.subItems.hasOwnProperty(idx) ? !(this.state as any).subItems[idx] : true
		this.setState({ subItems })
	}
	public openSubItem = (idx: number) => () => {
		const subItems: ISubItems = { ...this.state.subItems }
		subItems[idx] = true
		this.setState({ subItems })
	}
	public renderNavItem = (navItem: IMenuItem, idx: number) => {
		if (navItem.hideFromNav) return
		return (
			<li key={idx} className={cn("demo-nav__item", { "demo-nav__item--open": !!(this.state as any).subItems[idx] })}>
				<div className="demo-nav__item-desc">
					{navItem.children && (
						<button className="demo-nav__item-toggle" onClick={this.toggleSubItem(idx)}>
							{expandIcon}
							<span className="sr-only">Expand/Collapse</span>
						</button>
					)}
					{navItem.path ? (
						<NavLink to={navItem.path} exact={navItem.path === "/"} className="demo-nav-link" activeClassName="demo-nav-link--active" onClick={!!navItem.children ? this.openSubItem(idx) : undefined}>
							<span>{navItem.title}</span>
							<>{navItem.controllerOptions && navItem.controllerOptions.beta && <span className="demo-beta-badge"></span>}</>
						</NavLink>
					) : (
							<>
								{navItem.children ? (
									<button className="demo-nav-link" onClick={this.toggleSubItem(idx)}>
										<span className="demo-nav__item-label">{navItem.title}</span>
									</button>
								) : (
										<span className="demo-nav__item-label demo-nav__item-label--no-children">{navItem.title}</span>
									)}
							</>
						)}
				</div>
				{navItem.children && (
					<ul>
						{navItem.children.map(this.renderNavItem)}
					</ul>
				)}
			</li>
		)
	}
	public toggleExpanded = () => {
		this.props.setExpanded(!this.props.expanded)
	}
	public registerKeyboardShortcuts = () => {
		document.onkeyup = (e) => {
			if (e.altKey && e.which === 80) {
				if (this.filterField && this.filterField.current) this.filterField.current.focus()
			}
		}
	}
	public filterItems = () => {
		const matches: IMenuItem[] = []
		const matchingChildren: any = []
		for (const item of this.props.items) {
			const matchingItems: any[] = []
			if (item.children) {
				matchingItems.push(...item.children.filter((cItem, idx) => itemsFilter(cItem, this.state.filter, idx)))
			}
			if (matchingItems.length) {
				matchingChildren.push(...matchingItems)
				matches.push({ ...item, children: matchingItems })
			}
		}

		const subItems: any = {}
		matches.forEach((c, idx) => {
			subItems[idx] = true
		})

		const matchObject: any = {
			single: matchingChildren[0] && matchingChildren[0].path ? true : false,
			singlePath: matchingChildren[0] && matchingChildren[0].path ? matchingChildren[0].path : "",
			items: [...matches]
		}
		this.setState({ match: matchObject, subItems })
	}
	public handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			filter: e.target.value
		}, () => {
			if (this.state.filter) this.filterItems()
		})
	}
	public handleFilterKeydown = (e: React.KeyboardEvent) => {
		if (e.keyCode === 27) {
			this.setState({
				filter: ""
			})
		}
		if (e.keyCode === 40) {
			if (this.ulRef && this.ulRef.current) {
				e.preventDefault()
				const anchors = Array.from(this.ulRef.current.querySelectorAll("a"))
				if (anchors[0]) anchors[0].focus()
			}
		}
		if (e.keyCode === 13 && this.state.match.single) {
			if (this.state.match.singlePath) this.props.history.push(this.state.match.singlePath)
		}
	}
	public navigateList = (e: React.KeyboardEvent) => {
		if (e.keyCode === 40 || e.keyCode === 38) {
			e.preventDefault()
			if (this.ulRef && this.ulRef.current) findNextAnchorItem(this.ulRef.current, e.keyCode === 40 ? "down" : "up")
		}

		if (e.keyCode === 27 && this.state.filter) {
			this.setState({ filter: "" })
			if (this.ulRef && this.ulRef.current) {
				// pass focus on to first menu item
				const allAnchors = this.ulRef.current.querySelectorAll("a")
				if (allAnchors[0]) {
					allAnchors[0].focus()
				}
			}
		}
	}
	public toggleKeyboardMessage = (show: boolean) => () => {
		this.setState({ showKeyboardMessage: show })
	}
	public render() {
		const { match, filter, showKeyboardMessage } = this.state
		const { expanded } = this.props
		return (
			<>
				<div className={cn("demo-nav", { "demo-nav--expanded": expanded })}>
					<EasterEgg />
					<h1>Veracity Design System</h1>
					<div className="demo-nav__version">
						<a href="https://www.npmjs.com/package/@veracity/ccl">{version}</a>
					</div>
					<div className="demo-nav__content">
						<div className="demo-nav__input-container">
							{showKeyboardMessage && <span className="demo-tooltip"><strong>TIP!</strong> Use `Alt + P` to focus this field at any time</span>}
							<input
								type="text"
								ref={this.filterField}
								placeholder="Filter by name or tag"
								onKeyDown={this.handleFilterKeydown}
								onFocus={this.toggleKeyboardMessage(true)}
								onBlur={this.toggleKeyboardMessage(false)}
								aria-label="Filter by name or tag"
								value={filter}
								onChange={this.handleFilterChange}
							/>
						</div>
						<ul className="demo-nav__root-list" id="menu-root-list" ref={this.ulRef} onKeyDown={this.navigateList} tabIndex={-1}>
							{filter ? (
								<>
									{match.items.length ? (
										<>{match.items.map(this.renderNavItem)}</>
									) : (
											<>Sorry, noting found 😰</>
										)}
								</>
							) : (
									<>{this.props.items.map(this.renderNavItem)}</>
								)}
						</ul>
					</div>
				</div>
			</>
		)
	}
}
