import cloneDeep from "lodash/cloneDeep"
import { IMenuItem } from "../../interfaces"
import { ILoadableResourceObject } from "../../interfaces/ILoadableResourceObject"
import { hasProperties } from "../../utils/hasProperties"

export const getPromises = (data: IMenuItem): ILoadableResourceObject[] => {
	if (typeof data.controllerOptions === "function") {
		return [{
			name: "readme",
			fn: data.controllerOptions
		}]
	} else if (hasProperties(data, "controllerOptions.paths") && typeof data.controllerOptions.paths === "object") {
		return Object.entries(data.controllerOptions.paths).map((paths: any) => ({
			name: paths[0],
			fn: paths[1]
		}))
	} else {
		return []
	}
}

export const removePaths = (item: IMenuItem) => {
	item = cloneDeep(item)
	if (item.children) {
		item.children = item.children.map(removePaths)
	}

	if (typeof item.controllerOptions === "function") {
		item.controllerOptions = true
	}

	if (
		typeof item.controllerOptions === "object" &&
		item.controllerOptions &&
		item.controllerOptions.paths
	) {
		const newPaths: any = {}
		Object.keys(item.controllerOptions.paths).forEach((key) => newPaths[key] = true)
		item.controllerOptions.paths = newPaths
	}
	return item
}
