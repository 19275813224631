@mixin elements-table{
  table{
    width:100%;
    max-width:100%;
    margin-bottom:20px;
    box-sizing:border-box;
    border-spacing:0;
    border-collapse:collapse;
    &, *{
      box-sizing:border-box;
    }
    caption{
      padding:8px 0;
      color: $articleviewer-dusty-gray;
      text-align:left;
    }
    thead{
      th{
        border-bottom:3px solid $bodytext-table-heading-border;
        font-size:14px;
        padding:28px 16px 8px 16px;
        vertical-align:bottom;
        line-height:1.4;
        text-align:left;
        white-space:nowrap;
      }
    }
    tbody{
      th{
        font-weight:bold;
      }
      th, td{
        text-align:left;
        padding:16px;
        line-height:1.4;
        vertical-align:top;
        border-top:1px solid $bodytext-table-heading-border;
      }
      tr:first-child{
        th, td{
          border-top:0;
        }
      }
    }
    &.striped{
      tbody{
        tr:nth-child(2n+1){
          background-color: $bodytext-table-stripe;
        }
      }
    }
    &.framed{
      border:1px solid $bodytext-table-heading-border;
    }
    &.bordered{
      border:1px solid $bodytext-table-heading-border;
      thead{
        th{
          border-left:1px solid $bodytext-table-heading-border;
          &:first-child{
            border-left:0;
          }
        }
      }
      tbody{
        th, td{
          border-left:1px solid $bodytext-table-heading-border;
          &:first-child{
            border-left:0;
          }
        }
      }
    }
    &.condensed{
      thead{
        th{
          padding:16px 5px 5px 5px;
        }
      }
      tbody{
        th, td{
          padding:5px;
        }
      }
    }
    &.hover-highlight{
      overflow:hidden;
      tbody{
        tr:hover{
          background-color: $bodytext-table-hover;
        }
      }
    }
    &.hover-col-highlight{
      overflow:hidden;
      tbody, thead{
        td:hover, th:hover{
          position:relative;
          &::after{
            content: "";
            position:absolute;
            background-color: $bodytext-table-hover;
            left:0;
            top:-15000px;
            height:30000px;
            width:100%;
            z-index:-1;
          }
        }
      }
    }
  }
}