import React from "react"

import { IEnumProperty } from "../typeParser/interfaces"

export interface IEnumTableProps {
	members: IEnumProperty[]
}

export const EnumTable: React.StatelessComponent<IEnumTableProps> = (props) => {
	const {members} = props
	members.slice().sort((a, b) => a.name.localeCompare(b.name))

	return (
		<table className="hover-highlight striped condensed">
			<thead>
				<tr>
					<th>Name</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				{members.map((member) => (
					<tr key={member.name}>
						<td>{member.name}</td>
						<td>{member.description}</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
export default EnumTable
