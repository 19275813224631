// TODO: Fix so that these colors comes from a shared color variable set
$bodytext-link-foreground: rgb(0,103,187) !default;
$bodytext-inline-code-background: rgb(248,250,251) !default;
$bodytext-block-code-background: rgb(248,250,251) !default;
$bodytext-table-heading-border: rgb(206,213,230) !default;
$bodytext-table-row-border: rgb(204,204,204) !default;
$bodytext-table-stripe: rgb(248,250,251) !default;
$bodytext-table-hover: rgb(248,250,251) !default;

$articleviewer-blockquote-text-color: rgb(48,117,40) !default;
$articleviewer-concrete: rgb(242,242,242) !default;
$articleviewer-mercury: rgb(229,229,229) !default;
$articleviewer-silver: rgb(204,204,204) !default;
$articleviewer-dusty-gray: rgb(155,155,155) !default;
$articleviewer-mine-shaft: rgb(51,51,51) !default;
$articleviewer-white: rgb(255,255,255) !default;
$articleviewer-blizzard-blue: rgb(153,217,240) !default;
$articleviewer-botticelli: rgb(206,213,230) !default;
$articleviewer-science-blue: rgb(0,103,187) !default;
$articleviewer-alabaster: rgb(249,249,249) !default;

@import "./elements/_all";

.vui-body-text {
	@include elements;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility;
	color: $articleviewer-mine-shaft;
}
