@mixin elements-p{
  p{
    margin:24px 0;
    line-height:1.5;
    &.p-ingress{
      margin-bottom:40px;
      font-size:24px;
    }
    &:last-child{
      margin:0;
    }
  }
}