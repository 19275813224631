import React from "react"
import { IBodyTextProps } from "./BodyText.types"

import "./BodyText.scss"

export class BodyText extends React.PureComponent<IBodyTextProps> {
	public render() {
		const { className, classToProp, children, ...props } = this.props
		if (classToProp) {
			const propName = typeof classToProp === "string" ? classToProp : "className"
			if (!children) return null
			const child = React.Children.toArray(children)[0]
			if (!child || !React.isValidElement(child)) return null
			return React.cloneElement(child, {
				...(child.props || {}),
				...props,
				[propName]: `vui-body-text ${className || ""}`
			})
		}
		return (
			<div className={`vui-body-text ${className || "" }`} {...props}>
				{children}
			</div>
		)
	}
}

export default BodyText
