import { IBaseState } from "../interfaces"

export const initialStore: IBaseState = {
	components: {},
	ui: {
		isMenuOpen: true,
		fullscreenExample: false,
		menuItems: []
	}
}
