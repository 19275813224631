import React from "react"
import { Link } from "react-router-dom"
import { IMenuItem } from "../../interfaces"
import { ITagPageProps } from "./ITagPageProps"
import "./TagPage.scss"

export class TagPage extends React.PureComponent<ITagPageProps> {
	public state = {
		filter: ""
	}
	public findTaggedContent = () => {
		const taggedContent: any[] = []
		const recursiveFindTag = (component: IMenuItem) => {
			if (component.children) {
				component.children.map(recursiveFindTag)
			}
			if (component.controllerOptions && typeof component.controllerOptions === "object") {
				const opt = component.controllerOptions
				if (opt.tags) {
					if (opt.tags.indexOf(this.props.match.params.tag) > -1) taggedContent.push(component)
				}
			}
		}
		this.props.items.forEach(recursiveFindTag)
		return taggedContent
	}
	public setFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			filter: e.target.value
		})
	}
	public filterTagged = (item: any, index: number) => {
		const filter = this.state.filter.toLocaleLowerCase()
		let doesMatch = false
		if (item.name.toLocaleLowerCase().includes(filter)) doesMatch = true
		if (item.title.toLocaleLowerCase().includes(filter)) doesMatch = true
		if (item.controllerOptions && item.controllerOptions.tags) {
			item.controllerOptions.tags.forEach((tag: string) => {
				if (tag.toLocaleLowerCase().includes(filter)) doesMatch = true
			})
		}
		return doesMatch
	}
	public render() {
		const tagged = this.findTaggedContent()
		return (
			<div className="demo-tag-page">
				<h1>Tagged with <span className="demo-tag">{this.props.match.params.tag}</span></h1>
				{tagged.length > 0 ? (
					<>
						<input type="text" placeholder="Filter content" onChange={this.setFilter} value={this.state.filter} />
						<ul>
							{this.state.filter ? (
								<>{tagged.filter(this.filterTagged).map((component) => <li key={component.path}><Link to={component.path}>{component.name}</Link></li>)}</>
							) : (
								<>{tagged.map((component) => <li key={component.path}><Link to={component.path}>{component.name}</Link></li>)}</>
							)}
						</ul>
					</>
				) : (
					<p>Bummer! No matches found for tag "{this.props.match.params.tag}"</p>
				)}
			</div>
		)
	}
}
