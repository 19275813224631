import { connect } from "react-redux"
import * as componentDuck from "../../ducks/component.duck"
import { IBaseState } from "../../interfaces"
import { ILoadableResourceObject } from "../../interfaces/ILoadableResourceObject"
import { IMenuItem } from "./../../interfaces/IMenuItem"
import { ComponentLoader } from "./ComponentLoader"

const mapStateToProps = (state: IBaseState) => ({
	isLoading: componentDuck.getIsLoading(state)
})

const mapDispatchToProps = (dispatch: any) => ({
	loader: (data: IMenuItem, promises: ILoadableResourceObject[]) => dispatch(componentDuck.loadData(data, promises))
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentLoader)
