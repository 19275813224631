/* THIS FILE IS GENERATED. DO NOT EDIT IT. */
$prussian110: #001620;
$prussian: #002A3E;
$prussian90: #25485B;
$prussian80: #426072;
$slateGrey: #698091;
$slateGrey80: #8799A7;
$slateGrey60: #A5B3BD;
$slateGrey40: #C3CCD3;
$slateGrey20: #E1E6E9;
$slateGrey10: #F1F3F5;
$indigo110: #025582;
$indigo: #036BA3;
$indigo60: #68A6C8;
$indigo40: #A4CADE;
$indigo30: #BAD6E6;
$indigo20: #CDE1ED;
$indigo10: #E6F1F6;
$indigo5: #F2F7FA;
$mineGrey: #333333;
$mineGrey80: #646464;
$mineGrey60: #929292;
$mineGrey40: #B3B3B3;
$mineGrey20: #DADADA;
$mineGrey10: #F6F6F6;
$mineGrey5: #FBFBFB;
$amulet110: #3E714F;
$amulet: #6A9C73;
$amulet60: #93B79A;
$amulet40: #C3D7C7;
$amulet10: #F0F5F0;
$pink110: #A00082;
$pink: #DA00B1;
$pink40: #F099E0;
$pink10: #FBE5F7;
$rubin110: #860B16;
$rubin: #B53C40;
$rubin60: #CC6E71;
$rubin30: #E5A2A5;
$rubin10: #F0D8D9;
$yellowFlax110: #CBAA27;
$yellowFlax: #EEDB83;
$yellowFlax60: #F5E9B5;
$yellowFlax40: #F8F1CD;
$yellowFlax20: #FCF8E6;
