import { createAction, handleActions } from "redux-actions"
import { IBaseState, IMenuItem, IUIState } from "../interfaces"

const _ns = "demopage/ui/"
export const getState = (state: IBaseState): IUIState => state.ui
const createNsAction = (action: string, payload?: any) => createAction(_ns+action, payload) as any

export const setMenuOpen = createNsAction("SET_MENU_OPEN")
export const setMenuItems = createNsAction("SET_MENU_ITEMS")
export const setFullscreenExample = createNsAction("SET_FULLSCREEN_EXAMPLE")
export const getMenuOpen = (globalState: IBaseState) => getState(globalState).isMenuOpen
export const getFullscreenExample = (globalState: IBaseState) => getState(globalState).fullscreenExample
export const getMenuItems = (globalState: IBaseState) => getState(globalState).menuItems

export const updateMenuOpen = (val: boolean) => (dispatch: any) => {
	dispatch(setMenuOpen(val))
}

export const updateMenuItems = (items: IMenuItem[]) => (dispatch: any) => {
	dispatch(setMenuItems(items))
}

export const updateFullscreenExample = (val: boolean) => (dispatch: any) => {
	dispatch(setFullscreenExample(val))
}

export const reducer = handleActions({
	[setMenuOpen]: (state, action) => ({
		...state,
		isMenuOpen: !!action.payload
	}),
	[setFullscreenExample]: (state, action) => ({
		...state,
		fullscreenExample: !!action.payload
	}),
	[setMenuItems]: (state, action) => ({
		...state,
		menuItems: action.payload
	})
}, {
	ui: {
		isMenuOpen: true,
		fullscreenExample: false
	}
})

export default reducer
