@mixin elements-dl{
  dl{
    > dt{
      font-weight:bold;
      line-height:1.4;
    }
    > dd{
      margin:0 0 12px 0;
      line-height:1.5;
    }
  }
}