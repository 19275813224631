import Prism from "prismjs"
import "prismjs/components/prism-typescript"
import "prismjs/themes/prism.css"
// tslint:disable-next-line:no-var-requires
const Normalizer = require("prismjs/plugins/normalize-whitespace/prism-normalize-whitespace")

const NormalizerInst = new Normalizer({
	"remove-trailing": true,
	"remove-indent": true,
	"left-trim": true,
	"right-trim": true,
	"tabs-to-spaces": 2
	// additional options
	// 'break-lines': 80,
	// 'indent': 2,
	// 'remove-initial-line-feed': false,
	// 'tabs-to-spaces': 4,
	// 'spaces-to-tabs': 4
})

export const highlightHtml = (root: HTMLElement) => {
	const codeNodes = root.querySelectorAll("code")
	Array.prototype.slice.call(codeNodes).forEach((block) => {
		if (block.textContent) {
			block.innerHTML = Prism.highlight(NormalizerInst.normalize(block.textContent), Prism.languages.typescript)
		}
	})
	return root
}

export const highlightString = (str: string) => {
	return Prism.highlight(NormalizerInst.normalize(str), Prism.languages.typescript)
}
