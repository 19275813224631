import React from "react"
import "./Loading.scss"

export class Loading extends React.PureComponent {
	public render() {
		return (
			<div className="demo-loading">
				<div className="sk-folding-cube">
					<div className="sk-cube1 sk-cube"></div>
					<div className="sk-cube2 sk-cube"></div>
					<div className="sk-cube4 sk-cube"></div>
					<div className="sk-cube3 sk-cube"></div>
				</div>
				Loading...
			</div>
		)
	}
}
export default Loading
