@mixin elements-figure{
	figure{
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 16px 0 24px;
		figcaption{
			margin: 10px 0;
		}
		img{
			max-width: 100%;
		}
	}
}