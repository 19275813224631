// tslint:disable:no-console
import { createAction, handleActions } from "redux-actions"
import { IBaseState, IComponentState } from "../interfaces"
import { ILoadableResourceObject } from "../interfaces/ILoadableResourceObject"
import { hasProperties } from "../utils/hasProperties"
import { IMenuItem } from "./../interfaces/IMenuItem"

const _ns = "demopage/components/"
export const getState = (state: IBaseState): IComponentState => state.components
const createNsAction = (action: string, payload?: any) => createAction(_ns+action, payload) as any

export const setProperty = createNsAction("SET_PROPERTY")

export const getProperty = (globalState: IBaseState) => (component: string, prop: string) => {
	const state = getState(globalState)

	if (state.hasOwnProperty(component) && state[component].hasOwnProperty(prop)) {
		return (state[component] as any)[prop]
	}
	return undefined
}

export const getIsLoading = (globalState: IBaseState) => (component: string) => {
	const state = getState(globalState)
	if (state.hasOwnProperty(component)) {
		return !!(state as any)[component].isLoading
	}
	return true
}

export const loadData = (data: IMenuItem, promises: ILoadableResourceObject[]) => async (dispatch: any) => {
	dispatch(setProperty({prop: "isLoading", comp: data.name, value: true}))

	try {
		const resolved: any[]= await Promise.all(
			promises.map(async (item) =>  {
				try {
					const chunk = await item.fn()
					return {
						name: item.name,
						data: chunk
					}
				} catch (err) {
					return {
						name: item.name,
						err
					}
				}
			})
		)
		resolved.forEach((res) => {
			if (res.name === "demos") {
				dispatch(
					setProperty({prop: "typeDoc", comp: data.name, value: res.data.DemoComponent[data.name].typeDoc})
				)
				dispatch(
					setProperty({prop: "demos", comp: data.name, value: res.data.DemoComponent[data.name].demos})
				)
			} else if (res.name === "betaDemos") {
				dispatch(
					setProperty({prop: "betaTypeDoc", comp: data.name, value: res.data.DemoComponent[data.name].typeDoc})
				)
				dispatch(
					setProperty({prop: "betaDemos", comp: data.name, value: res.data.DemoComponent[data.name].demos})
				)
			} else {
				dispatch(
					setProperty({prop: res.name, comp: data.name, value: res.data})
				)
			}
		})

		if (data.path) {
			dispatch(setProperty({
				prop: "path",
				comp: data.name,
				value: data.path
			}))
		}

		const tags = {
			prop: "tags",
			comp: data.name,
			value: []
		}
		if (hasProperties(data, "controllerOptions.tags")) {
			tags.value = data.controllerOptions.tags
		}
		dispatch(setProperty(tags))

		const beta = {
			prop: "isBeta",
			comp: data.name,
			value: false
		}
		if (hasProperties(data, "controllerOptions.beta")) {
			beta.value = true
		}
		dispatch(setProperty(beta))

		const hasBetaDocs = {
			prop: "hasBetaDocs",
			comp: data.name,
			value: false
		}
		if (hasProperties(data, "controllerOptions.paths.betaReadme")) {
			hasBetaDocs.value = true
		}
		dispatch(setProperty(hasBetaDocs))
	} catch (e) {
		console.log(e)
	} finally {
		dispatch(setProperty({prop: "isLoading", comp: data.name, value: false}))
	}
}

export const getReadme = (state: IBaseState) => (component: string) => {
	const beta = getState(state)
	if (beta.hasOwnProperty(component)) {
		return (beta as any)[component].readme || ""
	}
	return ""
}

export const fetchReadme = (comp: string, demo: () => Promise<string>) => async (dispatch: any) => {
	try {
		const readme = await demo()
		dispatch(setProperty({prop: "readme", comp, value: readme}))
	} catch (e) {
		console.warn(e)
	}
}

export const updateIsBeta = (comp: string, value: boolean) => (dispatch: any) => {
	dispatch(setProperty({prop: "isBeta", comp, value}))
}

export const updateHasBetaDocs = (comp: string, value: boolean) => (dispatch: any) => {
	dispatch(setProperty({prop: "hasBetaDocs", comp, value}))
}

export const reducer = handleActions({
	[setProperty]: (state, action: any) => {
		const newState = {...state} as any
		const { prop, comp, value } = action.payload
		newState[comp] ? newState[comp][prop] = value : newState[comp] = {[prop]: value}
		return newState
	}
}, {
	components: {}
})

export default reducer
