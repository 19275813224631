import React from "react"
import { Link } from "react-router-dom"
import { ITagsProps } from "./ITagsProps"
import "./Tags.scss"

export class Tags extends React.PureComponent<ITagsProps> {
	public render() {
		if (!this.props.tags || !this.props.tags.length) return null
		return (
			<div className="demo-tags">
				{this.props.tags.map((tag) => <Link key={tag} to={`/tags/${tag}`} className="demo-tag">{tag}</Link>)}
			</div>
		)
	}
}
