import React from "react"
import FontLoader, { IFontConfig } from "../FontLoader"
import { IVeracityFontsProps } from "./VeracityFonts.types"

const avenirConfig: IFontConfig = {
	className: "font-avenir--loaded",
	name: "Avenir Next",
	configs: [
		{}, // Load the default version (regular)
		{weight: 700}
	]
}
const broaderViewConfig: IFontConfig = {
	className: "font-broader-view--loaded",
	name: "Broader View"
}

export class VeracityFonts extends React.PureComponent<IVeracityFontsProps> {
	public static defaultProps = {
		avenir: true,
		broaderView: false
	}

	public get fullConfig(): IFontConfig[] {
		const fullConfig: IFontConfig[] = (this.props.config || []).slice()
		if (this.props.avenir) {
			fullConfig.push(avenirConfig)
		}
		if (this.props.broaderView) {
			fullConfig.push(broaderViewConfig)
		}

		return fullConfig
	}

	public render() {
		return (
			<FontLoader config={this.fullConfig}/>
		)
	}
}
export default VeracityFonts
