import { connect } from "react-redux"
import { withRouter } from "react-router"
import * as componentDuck from "../../ducks/component.duck"
import { IBaseState } from "../../interfaces"

const endsWithBeta = (str: string): boolean => {
	const regExp = /(\/beta|\/beta\/)$/g
	return regExp.test(str)
}

export default (Component: any) => withRouter(connect((state: IBaseState, ownProps: any) => ({
	getProperty: (c: string, prop: string) => componentDuck.getProperty(state)(c, prop),
	isBetaRoute: endsWithBeta(ownProps.match.path)
}))(Component))
