export const hasProperties: any = (object: any, str: string) => {
	const keys = str.includes(".") ? str.split(".") : [str]
	if (keys.length === 1) {
		return hasProperty(object, keys[0])
	} else {
		if (hasProperty(object, keys[0])) {
			return hasProperties(object[keys[0]], keys.slice(1).join("."))
		} else {
			return false
		}
	}
}

const hasProperty = (obj: any, key: string) => obj.hasOwnProperty(key)
