import React from "react"
import "./Content.scss"

export class Content extends React.Component {
	public state = {
		height: window.innerHeight
	}
	public componentDidMount() {
		window.addEventListener("resize", this.adjustHeightForIE)
	}
	public componentWillUnmount() {
		window.removeEventListener("resize", this.adjustHeightForIE)
	}
	public adjustHeightForIE = () => {
		this.setState({
			height: window.innerHeight
		})
	}
	public render() {
		return (
			<main className="demo-main-content" style={{minHeight: this.state.height + "px"}}>
				<div>
					{this.props.children}
				</div>
			</main>
		)
	}
}
