import React from "react"
import TypeInfo from "../TypeInfo"
import { ITypeViewerProps } from "./ITypeViewerProps"
import "./TypeViewer.scss"

export class TypeViewer extends React.PureComponent<ITypeViewerProps> {
	public render() {
		return (
			<TypeInfo heading="Types" headingId="comp-types" typeDoc={this.props.types} />
		)
	}
}
