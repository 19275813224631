import React from "react"
import { CodeViewer } from "../CodeViewer"
import "./DemoViewer.scss"
import { IDemoViewerProps } from "./IDemoViewerProps"

export class DemoViewer extends React.PureComponent<IDemoViewerProps> {
	public state = {
		activeTab: "demo-0",
		toggle: {}
	}
	public componentDidMount() {
		if (window.location.hash) {
			this.setState({
				activeTab: window.location.hash.slice(1)
			})
		}
	}
	public componentDidUpdate(prevProps: IDemoViewerProps) {
		if (!prevProps.fullscreen && this.props.fullscreen) {
			document.body.classList.add("demo-example-viewer--overflow")
		} else {
			document.body.classList.remove("demo-example-viewer--overflow")
		}
	}
	public setActiveTab = (tab: string) => (e: React.MouseEvent) => {
		e.preventDefault()
		const href = (e.target as HTMLLinkElement).getAttribute("href")
		document.location.hash = href ? href : ""
		this.setState({
			activeTab: tab
		})
	}
	public renderDemoMenu = () => {
		if (this.props.demos.length) {
			if (this.props.demos.length > 1) {
				return this.props.demos.map((demo, index) => {
					return (
						<li key={demo.name}>
							<a href={`#demo-${index}`} onClick={this.setActiveTab(`demo-${index}`)} className={`${this.state.activeTab === "demo-" + index ? "active" : ""}`}>
								{demo.name}
							</a>
						</li>
					)
				})
			}
			return []
		}
	}
	public toggleCode = (index: number) => () => {
		const toggleState = {...this.state.toggle} as any
		toggleState[index] = toggleState.hasOwnProperty(index) ? !(this.state as any).toggle[index] : true
		this.setState({
			toggle: toggleState
		})
	}
	public getToggleStyle = (index: number) => {
		return {
			display: (this.state as any).toggle[index] ? "block" : "none"
		}
	}
	public setFullscreen = () => {
		this.props.setFullscreen(!this.props.fullscreen)
	}
	public renderFullscreenButton = () => (
		<button onClick={this.setFullscreen} className="demo-example-preview-button">
			<svg viewBox="0 0 24 24" height="24" width="24">
				{this.props.fullscreen ? (
					<path xmlns="http://www.w3.org/2000/svg" fill="#bfbfbf" d="M16.586 19.414l-2.586 2.586v-8h8l-2.586 2.586 4.586 4.586-2.828 2.828-4.586-4.586zm-13.758-19.414l-2.828 2.828 4.586 4.586-2.586 2.586h8v-8l-2.586 2.586-4.586-4.586zm16.586 7.414l2.586 2.586h-8v-8l2.586 2.586 4.586-4.586 2.828 2.828-4.586 4.586zm-19.414 13.758l2.828 2.828 4.586-4.586 2.586 2.586v-8h-8l2.586 2.586-4.586 4.586z"/>
				) : (
					<path xmlns="http://www.w3.org/2000/svg" fill="#bfbfbf" d="M21.414 18.586l2.586-2.586v8h-8l2.586-2.586-5.172-5.172 2.828-2.828 5.172 5.172zm-13.656-8l2.828-2.828-5.172-5.172 2.586-2.586h-8v8l2.586-2.586 5.172 5.172zm10.828-8l-2.586-2.586h8v8l-2.586-2.586-5.172 5.172-2.828-2.828 5.172-5.172zm-8 13.656l-2.828-2.828-5.172 5.172-2.586-2.586v8h8l-2.586-2.586 5.172-5.172z"/>
				)}
			</svg>
		</button>
	)
	public watchEsc = (e: React.KeyboardEvent) => {
		e.persist()
		if(e.keyCode === 27) {
			this.props.setFullscreen(false)
		}
	}
	public renderDemos = () => {
		if (this.props.demos.length) {
			return this.props.demos.map((demo, index) => {
				const {Component} = demo
				if (this.state.activeTab === "demo-" + index) {
					return (
						<div key={index} id={`demo-${index}`} className="demo-example-viewer">
							<div className={`demo-example-preview ${this.props.fullscreen ? "demo-example-preview--fullscreen" : ""}`} tabIndex={-1} onKeyDown={this.watchEsc}>
								{this.renderFullscreenButton()}
								{Component && <Component />}
							</div>
							<button onClick={this.toggleCode(index)} className="demo-toggle-example">View/hide code</button>
							<div style={this.getToggleStyle(index)}>
								<CodeViewer code={demo.source} />
							</div>
						</div>
					)
				}
			})
		}
	}
	public render() {
		return (
			<div className="demo-demo-viewer" id="comp-examples">
				{this.props.demos.length > 0 &&
					<>
						<h2>{this.props.demos.length > 1 ? "Examples" : "Example"}</h2>
						<ul className="demo-example-nav">
							{this.renderDemoMenu()}
						</ul>
						{this.renderDemos()}
					</>
				}
			</div>
		)
	}
}
export default DemoViewer
