import React from "react"
import { MarkdownViewer } from "../../components/MarkdownViewer"
import { IControllerProps } from "../shared/IControllerProps"

export class UtilController extends React.PureComponent<IControllerProps> {
	public render() {
		const { name, getProperty } = this.props
		const readme = getProperty(name, "readme")
		return (
			<MarkdownViewer md={readme || ""} />
		)
	}
}
