import React from "react"

import { IInterfaceProperty, InterfacePropertyType } from "../typeParser/interfaces"

export interface IInterfaceTableProps {
	members: IInterfaceProperty[]
}

interface ISection {
	title: string
	members: IInterfaceProperty[]
}

export const InterfaceTable: React.StatelessComponent<IInterfaceTableProps> = (props) => {
	const {members} = props
	const sections: ISection[] = [
		{
			title: "Required members",
			members: []
		},
		{
			title: "Optional members",
			members: []
		},
		{
			title: "Deprecated members",
			members: []
		}
	]
	members.slice().sort((a, b) => a.name.localeCompare(b.name)).forEach((member) => {
		let insertAt = 2
		if (member.interfacePropertyType === InterfacePropertyType.required) {
			insertAt = 0
		}
		if (member.interfacePropertyType === InterfacePropertyType.optional) {
			insertAt = 1
		}
		sections[insertAt].members.push(member)
	})

	const nonEmptySections = sections.filter((sec) => sec.members.length > 0)

	return (
		<table className="hover-highlight striped condensed">
			<thead>
				<tr>
					<th>{" "}</th>
					<th>Name</th>
					<th>Type</th>
					<th>Default value</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				{nonEmptySections.map((section, idx) => (
				<React.Fragment key={idx}>
					<tr className="section-heading">
						<td colSpan={5}>{section.title} ({section.members.length})</td>
					</tr>
					{section.members.map((member) => (
					<tr key={member.name}>
						<td/>
						<td>{member.name}</td>
						<td className="colwidth-s" title={member.type}><code>{(member.type || "").trim()}</code></td>
						<td className="colwidth-max" title={member.defaultValue && member.defaultValue}>
							{member.defaultValue && (
								<code>{member.defaultValue.trim()}</code>
							)}
						</td>
						<td>{member.description}
							{member.interfacePropertyType === InterfacePropertyType.deprecated && ` ${member.deprecatedMessage}`}
						</td>
					</tr>
					))}
				</React.Fragment>
				))}
			</tbody>
		</table>
	)
}
export default InterfaceTable
