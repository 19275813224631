import React from "react"
import "./Icon.scss"
import { IconSize, IIconProps } from "./Icon.types"

const defaultSize = [30, 30]

const Icon: React.FunctionComponent<IIconProps> = (props) => {
	const {
		children,
		className,
		icon: SvgIcon,
		role = "img",
		size = IconSize.Medium,
		...otherProps
	} = props

	if (!otherProps["aria-hidden"] && !otherProps.hasOwnProperty("aria-label")) {
		otherProps["aria-hidden"] = "true"
	}

	const child = typeof SvgIcon === "function" ? <SvgIcon /> : React.Children.only(children)
	const widthHeight = (child as any).type.size as number[] || defaultSize

	if (!otherProps.viewBox) {
		otherProps.viewBox = [0, 0, ...widthHeight].join(" ")
	}

	let height: number
	let { width } = props

	if (!width) {
		height = size
		width = height / widthHeight[1] * widthHeight[0]
	} else {
		height = width / widthHeight[0] * widthHeight[1]
	}

	return (
		<svg
			className={"vui-icon " + (className ? className : "")}
			role={role}
			width={width}
			height={height}
			{...otherProps}
		>
			{child}
		</svg>
	)
}

export default Icon
