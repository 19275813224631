import { ComponentController } from "./ComponentController"
import { MdController } from "./MdController"
import ControllerConnector from "./shared/Controller.connector"
import { UtilController } from "./UtilController"

export const registry = {
	component: ControllerConnector(ComponentController),
	util: ControllerConnector(UtilController),
	md: ControllerConnector(MdController)
}
