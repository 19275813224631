import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { registry } from "../../controllers"
import { IMenuItem } from "../../interfaces"
import { hasProperties } from "../../utils/hasProperties"
import { ComponentLoader } from "../ComponentLoader"
import { MainLayout } from "../Layout/MainLayout"
import { NotFound } from "../NotFound"
import { TagPage } from "../TagPage"
import { IRoutingProps, IRoutingState, IStateItem } from "./IRoutingProps"
import { getPromises } from "./routingUtils"

export class Routing extends React.PureComponent<IRoutingProps, IRoutingState> {
	public state: IRoutingState = {
		items: []
	}
	public componentDidMount() {
		this.props.setMenuItems(this.props.items)
		this.makePages()
	}
	public makePages = () => {
		const data: IStateItem[] = []
		const mapNav = (item: IMenuItem, path?: string): void => {
			const workingItem = {...item}
			if (workingItem.children) {
				workingItem.children.forEach((it) => mapNav(it, it.path ? it.path : undefined))
				delete workingItem.children
			}

			if (workingItem.path && workingItem.controllerName) {
				(workingItem as IStateItem).component = (registry as any)[workingItem.controllerName]
				data.push(workingItem as IStateItem)
			}
		}
		this.props.items.forEach((item) => mapNav(item))
		this.setState({items: [...data]})
	}
	public getRoutes() {
		return this.state.items.map((data: IMenuItem, index: number) => {
			const promises = getPromises(data)
			if (hasProperties(data, "controllerOptions.paths.betaReadme")) {
				return [
					<Route exact path={data.path} render={() => <ComponentLoader promises={promises} data={data} key={index} />} />,
					<Route exact path={data.path + "/beta"} render={() => <ComponentLoader promises={promises} data={data} key={index + "beta"} />} />
				]
			}
			return <Route exact path={data.path} render={() => <ComponentLoader promises={promises} data={data} />} key={index}  />
		})
	}
	public render() {
		let lastLocation: string
		return (
			<Router>
				<MainLayout>
					<Switch>
						{this.getRoutes()}
						<Route exact path="/tags/:tag" component={TagPage} />
						<Route component={NotFound}/>
					</Switch>
					<Route render={(props) => {
						// scroll to top on javascript navigation pathname change
						if (!lastLocation || props.location.pathname !== lastLocation) {
							lastLocation = props.location.pathname
							window.scrollTo(0, 0)
						}
						return null
					}} />
				</MainLayout>
			</Router>
		)
	}
}
