import React from "react"
import { highlightString } from "../../utils/codeHighlighter"
import { ICodeViewerProps } from "./ICodeViewerProps"

import "./CodeViewer.scss"

export class CodeViewer extends React.PureComponent<ICodeViewerProps> {
	public highlightCode = () => {
		if (this.props.code && typeof this.props.code === "string") {
			const highlighted = highlightString(this.props.code)
			return {__html: highlighted}
		}
		return {__html: ""}
	}
	public render() {
		return (
			<div className="demo-code-viewer">
				<pre style={{backgroundColor: "#F8FAFB"}}>
					<code dangerouslySetInnerHTML={this.highlightCode()}></code>
				</pre>
			</div>
		)
	}
}
