import React from "react"

export interface IHeaderProps {
	heading: string
	getProperty: (name: string, prop: string) => any
}

import "./Header.scss"

export class Header extends React.PureComponent<IHeaderProps> {
	public render() {
		const { heading, getProperty } = this.props
		const isBeta = getProperty(heading, "isBeta")
		return (
			<header className="comp-header">
				<h1>{heading} {!!isBeta && <span className="demo-beta-badge"></span>}</h1>
			</header>
		)
	}
}
export default Header
