@mixin elements-headings{
  h1,h2,h3,h4,h5,h6{
    font-weight:bold;
    line-height:1.3125;
    margin:24px 0 12px 0;
    font-weight: 400;
  }
  h1{
    font-size:28px;
    font-weight: 600;
  }
  h2{
    font-size:24px;
    margin-top:12px;
  }
  h3{
    font-size:20px;
    margin-top:12px;
  }
  h4{
    font-size:18px;
    margin-top:12px;
    font-weight: 700;
  }
  h5{
    font-size:16px;
    margin:12px 0 10px 0;
    font-weight: 700;
  }
  h6{
    font-size:14px;
    margin:12px 0 10px 0;
    text-transform:uppercase;
    font-weight: 700;
  }
}