import React from "react"

export class NotFound extends React.PureComponent {
	public render() {
		return (
			<div>
				<h1>Whoops, page not found</h1>
				<p>Now where did this component go? 🤔</p>
			</div>
		)
	}
}
